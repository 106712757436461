
/*
gsap.registerPlugin(ScrollTrigger);

if (ScrollTrigger.isTouch !== 1) {
  if (document.querySelector('.services-section .title'))
    gsap.fromTo(
        '.services-section .title',
        {
          y: 100,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
        }
    );
}

*/

