import './utils/vh.utils.js';
import './utils/animate-on-view.utils.js';
import './utils/tilt.js';
import './utils/adaptive-cursor.utils.js';

import './components/animations.component.js';
import './components/meta-select.component.js';
import './components/meta-range.component.js';
import './components/uploadFile.component.js';
import './components/player.component.js';
import './components/inputs.component.js';
import './components/header.component.js';
import './components/motto-swiper.component.js';
import './components/work-swiper.component.js';
import './components/resources-swiper.component.js';
import './components/footer-form-swiper.component.js';
import './components/sliding-section.component.js';
import './components/custom-code.component.js';
import './components/gsap.component.js';
import './components/hero-animation.component.js';
import './components/swiper-our-values.component.js';
import './components/swiper-our-talent.component.js';
import './components/swiper-our-sectors.component.js';
import './components/swiper-clients-say.component.js';
import './components/swiper-filters.component.js';
import './components/swiper-count.component.js';
import './components/swiper-our-promises.component.js';
import './components/swiper-testimonials.component.js';
import './components/swiper-our-traditions.component.js';
import './components/jobs.component.js';
import './components/contact.component.js';

import './components/our-services.component.js';
import './components/videos.component.js';

import './libs/countUp.lib.js';

import Scrollbar from 'smooth-scrollbar';

if(document.querySelector('[data-scroll]')) {
  const scrollElArr = document.querySelectorAll('[data-scroll]');
  scrollElArr.forEach(el => {
    Scrollbar.init(el, {
      alwaysShowTracks: true,
    });
  })
}






