let isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
window.adaptiveCursor = function adaptiveCursor(selector){
    const adaptiveCursorItems = document.querySelectorAll(selector);
    if(adaptiveCursorItems.length){
        adaptiveCursorItems.forEach(el=>{
            if(!el.classList.contains('is-init')) {
                el.classList.add('is-init');

                if(!isTouch){
                    el.addEventListener('mousemove', function(e){
                        let x = e.offsetX;
                        let y = e.offsetY;
                        el.style.setProperty('--x', x+'px');
                        el.style.setProperty('--y', y+'px');
                    })

                }else{
                    el.classList.add('disabled-cursor');
                }
            }
        })
    }
}
adaptiveCursor('[data-adaptive-cursor]')