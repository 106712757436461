import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import {marqueeSwiperFunction} from "../utils/swiperMarquee.utils.js";

let ourSectorsSwiperWr = document.querySelectorAll('.our-sectors-swiper-wr');
ourSectorsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      observer: true,
      observeParents: true,
      spaceBetween: 20,
      slidesPerView: 1,
      speed: 1200,
      resistanceRatio: 0.85,
      freeMode: true,
      effect: 'fade',
      loop: slidesCount > 5 ? true : false,
      loopedSlides: slidesCount > 5 ? slidesCount * 2 : false,
      threshold: 10,

      breakpoints: {
        551: {
          spaceBetween: 16,
          slidesPerView: 2,
        },
        768: {
          spaceBetween: 16,
          slidesPerView: 3,
        },
        1024: {
          spaceBetween: 24,
          slidesPerView: 3,
        },
      },
    });

    marqueeSwiperFunction(swiper, {
      speed: 0.77,
    });
  }
});
