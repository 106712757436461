import { Navigation, Autoplay } from 'swiper';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

// Courses slider

let ourTraditionsSwiperWr = document.querySelectorAll('.our-traditions-swiper-wr');

ourTraditionsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');


    swiperOnResize('(max-width: 992px)', swiperEl, {
      modules: [Navigation, Autoplay],
      spaceBetween: 14,
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      breakpoints: {
        640: {
          spaceBetween: 24,
        },
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      on: {
        afterInit: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },

        resize: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },
      },
    });
  }
});