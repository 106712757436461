import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let ourValuesSwiperWr = document.querySelectorAll('.our-values-swiper-wr');
ourValuesSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      spaceBetween: 12,
      slidesPerView: 1,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        650: {
          slidesPerView: 1,
          spaceBetween: 16,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        }
      },

      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });
    // swiperObserver(swiper);
  }
});
