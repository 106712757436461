const header = document.querySelector('.header');

if(header) {
  // Header scroll
  const scrollContainer = () => document.documentElement || document.body;

// menu handlers

// check mobile menu show/hide condition
  const mobileMenuStartPoint = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
          '--mobile-menu-start-point'
      )
  );
  let isMobileMenuEnable =
      window.outerWidth <= mobileMenuStartPoint ||
      document.querySelector('.header-mobile');

// toggle menu handler
  const menuToggle = document.querySelector('.menu-toggle-wr');
  const menuCloseBtn = document.querySelector('.menu-close');

  function menuToggleFunc() {
    const navbarNav = document.querySelector('.main-menu');
    navbarNav.classList.toggle('active');

    // LockScroll when burger open and enable when closed and enable scroll on menu
    if (scrollLock.getScrollState()) {
      scrollLock.disablePageScroll(navbarNav.querySelector('.navbar-nav .menu'));
      scrollLock.addScrollableSelector('.navbar-nav .simplebar-content-wrapper');
    } else {
      scrollLock.enablePageScroll();
    }
  }
// end of toggle menu handler

  menuToggle.addEventListener('click', menuToggleFunc);
  menuCloseBtn.addEventListener('click', menuToggleFunc);


// const header = document.querySelector('.header');
  /*let lastScrollY = window.scrollY;

  window.addEventListener('scroll', () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > 10) {
      header.classList.add('scrolled');
    } else {
      header.classList.remove('scrolled');
      header.classList.remove('scrolled-hide');
    }

    if (currentScrollY > lastScrollY && currentScrollY > 10) {
      header.classList.remove('scrolled-hide');
    } else if (currentScrollY < lastScrollY && currentScrollY > 10) {
      header.classList.add('scrolled-hide');
    }

    lastScrollY = currentScrollY;
  });*/


// Hide and show Header when scroll up
  function headerSticky() {
    let prevScrollPos = window.pageYOffset;

    window.onscroll = () => {
      const currentScrollPos = window.pageYOffset;
      const headerHeight = header.scrollHeight;

      if (window.scrollY > 0) {
        if (prevScrollPos > currentScrollPos) {
          header.style.top = 0;
          header.classList.add('scrolled');
        } else {
          header.style.top = `-${headerHeight + 3}px`;
        }
      }

      if (window.scrollY === 0) {
        header.classList.remove('scrolled');
      }

      prevScrollPos = currentScrollPos;
    };

    if (window.scrollY !== 0) {
      header.classList.add('scrolled');
    }
  }

  headerSticky()
}


