const mobileMenuStartPoint = parseInt(
  getComputedStyle(document.documentElement).getPropertyValue(
    '--mobile-menu-start-point'
  )
);

let header = document.getElementById('header');
let scrollEl = document.getElementById('hero-slide-hiring');
let heroFixed = document.getElementsByClassName('hero-home')[0];
let headerButtons = header.querySelectorAll('.btn-wr .btn-outline');
let burgerButtons = header.querySelectorAll('.menu-toggle');
// let windowWidth = window.innerWidth;
let windowWidth = window.innerWidth;
let transform = 0;
function invertElements(elArray, transform) {
  let scrollTransform = windowWidth - window.scrollY;
  if (elArray.length) {
    elArray.forEach((button) => {

      let offsetLeft = button.getBoundingClientRect().left;
      let offsetRight = offsetLeft + button.offsetWidth;
      let clipPoint = offsetRight - scrollTransform;
      if (offsetRight >= scrollTransform && offsetLeft <= scrollTransform) {
        button.style.setProperty('--clip-point', clipPoint + 'px');
      } else if (offsetLeft > scrollTransform) {
        button.style.setProperty('--clip-point', button.offsetWidth + 'px');
      } else {
        button.style.setProperty('--clip-point', '0px');
      }
    });
  }
}

let windowScrollY = 0;
function pageSlide() {
  if (windowWidth > mobileMenuStartPoint && heroFixed) {
     transform = window.scrollY;
    if (transform <= windowWidth && windowWidth > mobileMenuStartPoint) {
      document.documentElement.style.setProperty(
        '--intro-transform',
        `${transform}px`
      );

      heroFixed.classList.remove('absolute');
      scrollEl.classList.remove('absolute');
      header.classList.remove('scrolled');
     /* header.classList.remove('scrolled-start');*/

    } else {
      document.documentElement.style.setProperty('--intro-transform', `0`);
      heroFixed.classList.add('absolute');
      scrollEl.classList.add('absolute');
      header.classList.add('scrolled');
     /* header.classList.add('scrolled-start');*/


      if(windowScrollY < window.scrollY){
        header.classList.add('scrolled-hide');
      }else{
        header.classList.remove('scrolled-hide');
      }



      windowScrollY = window.scrollY;

    }
    invertElements(headerButtons, transform);
    invertElements(burgerButtons, transform);
  }
}

window.addEventListener('scroll', pageSlide);
window.addEventListener('resize', pageSlide);
window.addEventListener('resize', function () {
  windowWidth = document.documentElement.clientWidth;
});

pageSlide();
