if(document.querySelector('#product-card-svg')) {
  const petaAnimation = anime({
    targets: '#product-card-svg path',
    strokeDashoffset: [anime.setDashoffset, 0],
    easing: 'easeInOutSine',
    duration: 3000,
    delay: function(el, i) { return i * 1 },
    direction: 'normal',
    loop: false
  });
  petaAnimation.pause();

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setTimeout(() => {
          petaAnimation.play();
        }, 600);
        observer.unobserve(document.querySelector('#product-card-svg'));
      }
    });
  }, { threshold: 0, rootMargin: '0% 0% -10% 0%' });

  observer.observe(document.querySelector('#product-card-svg'));
}