const contactSection = document.querySelector('.contact-section');
if (contactSection) {
  const tabs = contactSection.querySelectorAll('.contact-section__tab');
  const forms = contactSection.querySelectorAll('.contact-section__form');

  tabs.forEach((tab, i) => {
    tab.addEventListener('click', (e) => {
      for (let j = 0; j < tabs.length; j++) {
        tabs[j].classList.remove('active');
        forms[j].classList.remove('active');
      }

      tab.classList.add('active');
      forms[i].classList.add('active');
    });
  });
}
