import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let filtersSwiperWr = document.querySelectorAll('.filters-swiper-wr');
filtersSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    const swiperMobileHelper = swiperEl.querySelector('.swiper-mobile-helper');

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 'auto',
      spaceBetween: 0,
    });

    setTimeout(function () {
      swiperMobileHelper.classList.add('show');
    }, 300);
  }
});
