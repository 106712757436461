const ourServicesSection = document.querySelector('.our-services-section');
if (ourServicesSection) {
  const ourServicesArr = ourServicesSection.querySelectorAll(
    '.our-services-section__container'
  );
  ourServicesArr.forEach((ourServices) => {
    const items = ourServices.querySelectorAll('.our-services-section__item');

    items.forEach((item, i) => {
      item.addEventListener('click', function () {
        for (let j = 0; j < items.length; j++) {
          items[j].classList.remove('active');
        }

        item.classList.add('active');
      });
    });
  });
}
