import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

const videosContainerArr = document.querySelectorAll(
  '.videos-section__container'
);
let videosSwiperWr = document.querySelectorAll('.videos-swiper-wr');
videosSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    swiperOnResize('(max-width: 767px)', swiperEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 20,
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });
  }
});

videosContainerArr.forEach((videosContainer) => {
  const itemsArr = videosContainer.querySelectorAll('.videos-section__item');
  const togglesArr = videosContainer.querySelectorAll(
    '.videos-section__toggle'
  );

  togglesArr.forEach((toggle, i) => {
    toggle.addEventListener('click', () => {
      for (let j = 0; j < togglesArr.length; j++) {
        togglesArr[j].classList.remove('active');
        itemsArr[j].classList.remove('active');
      }

      toggle.classList.add('active');
      itemsArr[i].classList.add('active');
    });
  });
});
