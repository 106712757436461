import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let footerFormSwiperWr = document.querySelectorAll('.footer-form-swiper-wr');
footerFormSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let submitEl = el.querySelector('.swiper-button-submit');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    submitEl.style.display = 'none';
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      spaceBetween: 20,
      slidesPerView: 1,
      speed: 800,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      on: {
        slideChange: function () {
          if (swiper.activeIndex === slidesCount - 1) {
            nextEl.style.display = 'none';
            submitEl.style.display = 'flex';
          } else {
            nextEl.style.display = 'flex';
            submitEl.style.display = 'none';
          }
        }
      }
    });
  }
});

const inputsWithPlaceholders = [
  {
    element: document.querySelector('.name-placeholder-input'),
    placeholderTexts: ['James…', 'Richard…', 'Jennifer…', 'Omar…', 'Monika…'],
  },
  {
    element: document.querySelector('.email-placeholder-input'),
    placeholderTexts: ['Type here…', 'Type here…'],
  },
  {
    element: document.querySelector('.message-placeholder-input'),
    placeholderTexts: ['Type here…', 'Type here…'],
  }
];

let currentTextIndices = Array(inputsWithPlaceholders.length).fill(0);
let currentCharIndices = Array(inputsWithPlaceholders.length).fill(0);
let isDeletingFlags = Array(inputsWithPlaceholders.length).fill(false);
let hasStarted = false;

function typeEffect(inputIndex) {
  const { element, placeholderTexts } = inputsWithPlaceholders[inputIndex];
  let currentText = placeholderTexts[currentTextIndices[inputIndex]];

  if (isDeletingFlags[inputIndex]) {
    currentCharIndices[inputIndex]--;
    element.placeholder = currentText.slice(0, currentCharIndices[inputIndex]);

    if (currentCharIndices[inputIndex] === 0) {
      isDeletingFlags[inputIndex] = false;
      currentTextIndices[inputIndex] = (currentTextIndices[inputIndex] + 1) % placeholderTexts.length;
    }
  } else {
    currentCharIndices[inputIndex]++;
    element.placeholder = currentText.slice(0, currentCharIndices[inputIndex]);

    if (currentCharIndices[inputIndex] === currentText.length) {
      isDeletingFlags[inputIndex] = true;
      setTimeout(() => typeEffect(inputIndex), 2000); // Пауза перед удалением
      return;
    }
  }

  setTimeout(() => typeEffect(inputIndex), isDeletingFlags[inputIndex] ? 50 : 100);
}

function startTypingAnimations() {
  if (!hasStarted) {
    hasStarted = true;
    inputsWithPlaceholders.forEach((_, index) => {
      setTimeout(() => typeEffect(index), index * 1000); // Начало печати с задержкой для каждого инпута
    });
  }
}

const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      startTypingAnimations();
      observer.disconnect();
    }
  });
});

inputsWithPlaceholders.forEach(({ element }) => observer.observe(element));
