import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
let testimonialsSwiperWr = document.querySelectorAll('.testimonials-swiper-wr');
testimonialsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      spaceBetween: 16,
      slidesPerView: 1,
      threshold: 10,
      loop: slidesCount > 3 ? true : false,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        640: {
          spaceBetween: 24,
        },
        768: {
          spaceBetween: 42,
        },
        1200: {
          spaceBetween: 84,
        }
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth >= 640,
      },
      on:{
      afterInit: function(){
            wrapper.scrollWidth >= wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
        },
        resize: function(){
            wrapper.scrollWidth >= wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
        }
      }
    });
    swiperObserver(swiper);
  }
});


