import Swiper, { Navigation } from 'swiper';

let ourPromisesSwiperWr = document.querySelectorAll('.our-promises-swiper-wr');
ourPromisesSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slides = el.querySelectorAll('.swiper-slide');

    let autoplayInterval = window.innerWidth > 1024 ? 4000 : 7500;
    let lastSwitchTime = Date.now();
    let pausedProgress = 0;
    let isHovered = false;
    let timer;

    let swiper = new Swiper(swiperEl, {
      modules: [Navigation],
      observer: true,
      observeParents: true,
      spaceBetween: 64,
      slidesPerView: 1,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      autoHeight: false,
      on: {
        slideChange: resetProgress,
      },
    });

    function startCustomAutoplay() {
      lastSwitchTime = Date.now();
      timer = requestAnimationFrame(updateCustomAutoplay);
    }

    function updateCustomAutoplay() {
      if (!isHovered) {
        let elapsedTime = Date.now() - lastSwitchTime;
        let totalElapsedPercent = Math.min((elapsedTime / autoplayInterval) * 100 + pausedProgress, 100);

        slides.forEach(slide => {
          slide.style.setProperty('--before-width', `${totalElapsedPercent}%`);
        });

        if (totalElapsedPercent >= 100) {
          if (swiper.isEnd) {
            swiper.slideTo(0);
          } else {
            swiper.slideNext();
          }
          resetProgress();
        }
      }
      timer = requestAnimationFrame(updateCustomAutoplay);
    }

    function resetProgress() {
      slides.forEach(slide => slide.style.setProperty('--before-width', `0%`));
      lastSwitchTime = Date.now();
      pausedProgress = 0;
    }

    slides.forEach(slide => {
      slide.addEventListener('mouseenter', () => {
        isHovered = true;
        cancelAnimationFrame(timer);

        let elapsedTime = Date.now() - lastSwitchTime;
        pausedProgress = Math.min((elapsedTime / autoplayInterval) * 100 + pausedProgress, 100);
      });

      slide.addEventListener('mouseleave', () => {
        isHovered = false;
        startCustomAutoplay();
      });
    });

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          startCustomAutoplay();
          observer.unobserve(el);
        }
      });
    }, { threshold: 0, rootMargin: '0% 0% -10% 0%' });

    observer.observe(el);
  }
});
