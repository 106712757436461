import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let workSwiperWr = document.querySelectorAll('.work-swiper-wr');
workSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      initialSlide: slidesCount > 1 ? 1 : 0,
      spaceBetween: 16,
      slidesPerView: 1,
      speed: 800,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        200: {
          initialSlide: slidesCount > 2 ? 2 : 0,
        },
        450: {
          slidesPerView: 1.5,
          spaceBetween: 16,
        },
        690: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 2.5,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },

      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });
    swiperObserver(swiper);
  }
});
