import Swiper from 'swiper';
import { swiperObserver } from './swiper.utils';
export const swiperOnResize = (
  breakpoint,
  swiperClass,
  swiperSettings,
  startOnView
) => {
  let swiper;

  breakpoint = window.matchMedia(breakpoint);

  const enableSwiper = function (className, settings) {
    swiper = new Swiper(className, settings);

    if (startOnView) {
      swiperObserver(swiper);
    }
  };

  const checker = function () {
    if (breakpoint.matches) {
      return enableSwiper(swiperClass, swiperSettings);
    } else {
      if (swiper !== undefined) swiper.destroy(true, true);
      return;
    }
  };

  breakpoint.addEventListener("change", checker);
  checker();
};